var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm._m(0);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    attrs: {
      id: "content"
    }
  }, [_c("div", {
    staticClass: "aboutBannerBox"
  }, [_c("img", {
    staticClass: "aboutBanner",
    attrs: {
      src: require("../assets/banner/gzhpt.jpg")
    }
  })]), _c("div", {
    staticClass: "companyBlock",
    staticStyle: {
      "padding-bottom": "0"
    }
  }, [_c("div", {
    staticClass: "companyBlock-about"
  }, [_c("div", {
    staticClass: "companyBlock-hd"
  }, [_c("h2", [_vm._v("公众号招聘平台")])]), _c("img", {
    staticClass: "corporate-values companyInfo1",
    attrs: {
      src: require("../assets/wx_recruit.jpg")
    }
  })])])]);
}];
render._withStripped = true;
export { render, staticRenderFns };